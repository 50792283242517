/*
* appHeader
*/

const appHeader = {
  cfg:  {
    name: 'appHeader',
    appHeader: document.getElementsByClassName('o-contactSection')[0],
    trigger: document.querySelector('.js-navigationMainTrigger'),
    breakpoint: 1024,
  },

  setBodyClass() {
    if (!document.body.classList.contains('is-open--navigation')) {
      document.body.classList.add('is-open--navigation');
    } else {
      document.body.classList.remove('is-open--navigation');
    }
  },

  removeBodyClass() {
    if (window.innerWidth >= this.cfg.breakpoint) {
      document.body.classList.remove('is-open--navigation');
    }
  },

  init() {
    let currentY = 0;
    const {body} = document;

    window.addEventListener('scroll', () => {
      if (currentY < window.scrollY) {
        body.classList.add('scrollDown');
        body.classList.remove('scrollUp');
      } else {
        body.classList.add('scrollUp');
        body.classList.remove('scrollDown');
      }

      if (window.scrollY > 51) {
        body.classList.add('scrollMin');
      } else {
        body.classList.remove('scrollMin');
      }

      if (window.scrollY > 250) {
        body.classList.add('scrollHide');
      } else {
        body.classList.remove('scrollHide');
      }

      currentY = window.scrollY;
    });

    if (this.cfg.trigger) {
      this.cfg.trigger.addEventListener('click', () => {
        this.setBodyClass();
      });
    }
  },
};

export default appHeader;
