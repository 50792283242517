/**
 * demoForm
 */
const demoForm = {
  cfg: {
    endpointImageAnalysis: '/actions/styleriser-api/styleriser-api/analyse-image',
    endpointContactStorage: '/actions/styleriser-api/styleriser-api/store-contact',
  },

  // Utility to toggle classes on certain elements
  toggleValidityStyleClasses(domObject, newClass, oldClass) {
    domObject.classList.remove(oldClass);
    domObject.classList.add(newClass);
  },
  validateEmail(inputText) {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return !!inputText.match(mailformat);
  },
  storeContact(emailAdress) {
    const srApiRequest = new XMLHttpRequest();
    srApiRequest.open('POST', this.cfg.endpointContactStorage, true);

    const srFormData = new FormData();
    srFormData.append('email', emailAdress);

    srApiRequest.onload = function() {
      console.log(this.response);
      // Hide Demo Form
      // document.getElementsByClassName('o-demoForm')[0].setAttribute('style', 'display:none');

      // Show results-container
      // Actual content of Container depends on state of API-Request (loading, success or error)
      // document.getElementsByClassName('o-demoResult')[0].setAttribute('style', 'display:block');
    };
    srApiRequest.send(srFormData);
  },
  analyzeImage(srImageBlob) {
    // Add image-preview of uploaded image-blob.
    const url = URL.createObjectURL(srImageBlob);
    const demoImage = document.getElementById('o-demoResult').getElementsByClassName('o-demoResult__image')[0].getElementsByTagName('img')[0];
    demoImage.src = url;

    // Create API-Request & Prepare Payload (srFormData)
    const srApiRequest = new XMLHttpRequest();
    srApiRequest.open('POST', this.cfg.endpointImageAnalysis, true);
    const srFormData = new FormData();
    srFormData.append('id', 10);
    srFormData.append('name', 'some-image.jpg');
    srFormData.append('image', srImageBlob, 'some-image.jpg');


    // Callback (get's executed, once Data has arrived)
    srApiRequest.onload = function() {
      // Error-Handling
      try {
        // On successful status-code
        if (srApiRequest.status >= 200 && srApiRequest.status < 400) {
          // Convert Response-String into JSON
          const responseJson = JSON.parse(this.response);
          const styleResultDomains = ['frame_shapes','necklines','pattern_category'];
          let styleResultApparelAttributes = [];
          let styleResultSeasonStyle = [];

          // Get DOM-Elements
          const demoResult = document.getElementsByClassName('o-demoResult__success')[0];
          const demoFaceshape = demoResult.getElementsByClassName('o-demoResult__faceshape')[0];
          const demoFacialToning = demoResult.getElementsByClassName('o-demoResult__facialtoning')[0];
          const demoStyleResult = demoResult.getElementsByClassName('o-demoResult__styleresult')[0];
          const demoProductColors = demoResult.getElementsByClassName('o-demoResult__productcolors')[0];
          const demoFrameTypes = demoResult.getElementsByClassName('o-demoResult__frametypes')[0];
          const demoFrameColors = demoResult.getElementsByClassName('o-demoResult__framecolors')[0];
          const demoNecklines = demoResult.getElementsByClassName('o-demoResult__necklines')[0];
          const demoPatterns = demoResult.getElementsByClassName('o-demoResult__patterns')[0];
          const demoColorTiles = demoResult.getElementsByClassName('a-colorTile');


          console.log(responseJson);

          if(responseJson.style_results.apparel_attributes) {
            styleResultApparelAttributes = demoForm.extractAttributes(responseJson.style_results.apparel_attributes,styleResultDomains);
          }

          if(responseJson.style_results.season_style) {
            styleResultSeasonStyle = demoForm.extractSeasonStyles(responseJson.style_results.season_style);
          }

          console.log(styleResultApparelAttributes);
          console.log(styleResultSeasonStyle);


          // If DOM-Element & Data for Faceshape exists
          if (demoFaceshape) {
            if(responseJson.faceshape) {
              demoFaceshape.getElementsByClassName('a-richText')[0].innerHTML = demoForm.getTagString(responseJson.faceshape);
            } else {
              demoFaceshape.setAttribute('style', 'display:none');
            }
          }

          if (demoFacialToning) {
            if (styleResultSeasonStyle.person.short_desc) {
              // eslint-disable-next-line max-len
              demoFacialToning.getElementsByClassName('a-richText')[0].innerHTML = styleResultSeasonStyle.person.short_desc;
            } else {
              demoFacialToning.setAttribute('style', 'display:none');
            }
          }

          if (demoStyleResult) {
            if (responseJson.facial_results &&
              responseJson.facial_results.faceshape_result &&
              responseJson.facial_results.faceshape_result.styles_result) {
              // eslint-disable-next-line max-len
              demoStyleResult.getElementsByClassName('a-richText')[0].innerHTML = demoForm.getTagString(responseJson.facial_results.faceshape_result.styles_result);
            } else {
              demoStyleResult.setAttribute('style', 'display:none');
            }
          }

          if (demoProductColors) {
            if (styleResultSeasonStyle.apparel.short_desc) {
              demoProductColors.getElementsByClassName('a-richText')[0].innerHTML = styleResultSeasonStyle.apparel.short_desc;
            } else {
              demoProductColors.setAttribute('style', 'display:none');
            }
          }

          if (demoFrameTypes) {
            if (styleResultApparelAttributes.frame_shapes) {
              // eslint-disable-next-line max-len
              demoFrameTypes.getElementsByClassName('a-richText')[0].innerHTML = demoForm.getTagString(styleResultApparelAttributes.frame_shapes);
            } else {
              demoFrameTypes.setAttribute('style', 'display:none');
            }
          }

          if (demoFrameColors) {
            if (styleResultSeasonStyle.eyewear.short_desc) {
              // eslint-disable-next-line max-len
              demoFrameColors.getElementsByClassName('a-richText')[0].innerHTML = styleResultSeasonStyle.eyewear.short_desc;
            } else {
              demoFrameColors.setAttribute('style', 'display:none');
            }
          }

          if (demoNecklines) {
            if (styleResultApparelAttributes.necklines) {
              // eslint-disable-next-line max-len
              demoNecklines.getElementsByClassName('a-richText')[0].innerHTML = demoForm.getTagString(styleResultApparelAttributes.necklines);
            } else {
              demoNecklines.setAttribute('style', 'display:none');
            }
          }

          if (demoPatterns) {
            if (styleResultApparelAttributes.pattern_category) {
              // eslint-disable-next-line max-len
              demoPatterns.getElementsByClassName('a-richText')[0].innerHTML = demoForm.getTagString(styleResultApparelAttributes.pattern_category);
            } else {
              demoPatterns.setAttribute('style', 'display:none');
            }
          }

          if (demoColorTiles) {
            if (responseJson.season_colors) {
              for (let i = 0; i < responseJson.season_colors.length; i++) {
                const background = `background-color:${responseJson.season_colors[i]}`;
                demoColorTiles[i].setAttribute('style', background);
              }
            } else {
              for (let i = 0; i < responseJson.season_colors.length; i++) {
                const background = `background-color:${responseJson.season_colors[i]}`;
                demoColorTiles[i].setAttribute('style', background);
              }
            }
          }
          // Display Results Div
          demoResult.setAttribute('style', 'display:block');
          document.getElementsByClassName('o-demoResult__loading')[0].setAttribute('style', 'display:none');
        } else {
          throw new Error('API Status-Code Error');
        }
      } catch (err) {
        // Display Error-Message
        document.getElementsByClassName('o-demoResult__error')[0].setAttribute('style', 'display:block');
        document.getElementsByClassName('o-demoResult__loading')[0].setAttribute('style', 'display:none');
      }
    };

    // Send the Request
    srApiRequest.send(srFormData);
    // Show Loading-State when Request gets send
    // It is still hidden by Parent Container, which only will be shown, once email has been saved.
    document.getElementsByClassName('o-demoResult__loading')[0].setAttribute('style', 'display:block');
    // document.getElementsByClassName('o-demoResult')[0].setAttribute('style', 'display:block');
  },

  init() {
    const _inputFile = document.querySelector('[name="demoUpload"]');
    const _inputEmail = document.querySelector('[name="demoEmail"]');
    const _uploadButton = document.querySelector('#demoUpload');
    const _UploadedInfo = document.querySelector('#demoUploaded');
    const _reUploadLink = document.querySelector('#demoReUpload');
    const _submitButton = document.querySelector('#demoSubmit');
    const _submitLoader = document.querySelector('.o-demoForm__loading');
    const _redoButton = document.querySelector('#demoRedo');

    if (_inputFile) {
      if(_reUploadLink) {
        _reUploadLink.addEventListener('click', () => {
          _inputFile.click();
        });
      }

      if (_uploadButton) {
        _uploadButton.addEventListener('click', () => {
          _inputFile.click();
        });

        _inputFile.addEventListener('change', el => {
          if (_inputFile.files.length === 0) {
            this.toggleValidityStyleClasses(_inputFile.parentNode,'is-invalid','is-valid');
          } else {
            this.toggleValidityStyleClasses(_inputFile.parentNode,'is-valid','is-invalid');
            if(_UploadedInfo) {
              _UploadedInfo.setAttribute('style', 'display:block');
              _uploadButton.setAttribute('style', 'display:none');
            }
            this.analyzeImage(el.target.files[0]);
          }
        });
      }
    }

    if (_inputEmail && _submitButton) {
      _inputEmail.addEventListener('keyup', el => {
        if (el.target.value === '') {
          _submitButton.disabled = true;
          this.toggleValidityStyleClasses(_submitButton,'a-button--styleDisabled','a-button--stylePrimary');
        } else {
          _submitButton.disabled = false;
          this.toggleValidityStyleClasses(_submitButton,'a-button--stylePrimary','a-button--styleDisabled');
        }
      });

      _submitButton.addEventListener('click',() => {
        if (this.validateEmail(_inputEmail.value)) {
          this.storeContact(_inputEmail.value);

          if (_inputFile.files.length === 0) {
            this.toggleValidityStyleClasses(_inputFile.parentNode,'is-invalid','is-valid');
          } else {
            this.toggleValidityStyleClasses(_inputFile.parentNode,'is-valid','is-invalid');

            if(_submitLoader) {
              _submitLoader.setAttribute('style', 'display:block');
              _submitButton.setAttribute('style', 'display:none');
            }
            setTimeout(() => {
              document.getElementById('o-demoResult').setAttribute('style','display:block');
              document.getElementById('o-demoForm').setAttribute('style','display:none');
              window.scrollTo({top: 0, behavior: 'smooth'});
            },2000);
          }
        }
      });
    }

    if(_redoButton) {
      _redoButton.addEventListener('click', () => {
        window.location.reload();
      });
    }
  },
  doNothing() {
    console.log('Do nothing');
  },
  extractAttributes(styleResultsJson, categories) {
    const styleResults = {};
    categories.forEach(category => {
      styleResults[category] = [];
      styleResultsJson.forEach(styleResultItem => {
        if (styleResultItem.category === category) {
          styleResults[category].push(styleResultItem.detail);
        }
      });
    });
    return styleResults;
  },
  extractSeasonStyles(seasonStylesJson) {
    const seasonStyles = {};
    seasonStylesJson.forEach(seasonStyleItem => {
      if(seasonStyleItem.desc_category === "Person")
        seasonStyles.person = seasonStyleItem;
      else if(seasonStyleItem.desc_category === "Apparel")
        seasonStyles.apparel = seasonStyleItem;
      else if(seasonStyleItem.desc_category === "Eyeware")
        seasonStyles.eyewear = seasonStyleItem;
    });
    return seasonStyles;
  },
  getTagArray(json) {
    const tags = [];
    Object.keys(json).forEach((key) => {
      if (json[key] === 'yes')
        tags.push(key);
    });
    return tags;
  },
  getTagString(tags) {
    const tagStrings = [];
    tags.forEach(tag => {
      tagStrings.push(tag.charAt(0).toUpperCase() + tag.slice(1).replaceAll('_',' '));
    });
    return tagStrings.join(', ');
  },
  getRequestUrL(mode, requestURI) {
    if (mode === 'dev') {
      return this.cfg.apiRootDev + requestURI;
    } else if (mode === 'prod') {
      return this.cfg.apiRootProd + requestURI;
    }
    return '';
  },
};
export default demoForm;
