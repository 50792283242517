/**
 * Scripts
 */

import example from '../scripts/example';
import bodyClass from '../scripts/bodyclass';
import disableHover from '../scripts/disableHover';
import browserDetect from '../scripts/bowser';
import resizeClass from '../scripts/resizeClass';
import scrollmonitor from '../scripts/scrollmonitor';
import '../scripts/lazyloading';
import '../scripts/fontfaceobserver';
// import '../scripts/pagetransition'
// import '../scripts/smoothScroll'
// import '../scripts/waypoints.anime'

// Modules Array
const scriptsArray = [
  example,
  bodyClass,
  disableHover,
  browserDetect,
  resizeClass,
  scrollmonitor,
];

const scripts = {
  // Scripts
  Scripts() {
    if (scriptsArray.length > 0) {
      scriptsArray.forEach((script) => {
        script.init();
      });
    }
  },

  init() {
    this.Scripts();
  },
};

export default scripts;
