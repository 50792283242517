/**
 * modules
 */

import codeSnippet from '../../../templates/_atoms/codeSnippet/_script';
// import contactSection from '../../../templates/_organisms/contactSection/_script';
import demoForm from '../../../templates/_organisms/demoForm/script';
import imageSlider from '../../../templates/_organisms/imageSlider/_script';
import imageGallery from '../../../templates/_organisms/imageGallery/_script';
import input from '../../../templates/_atoms/input/_script';
import textarea from '../../../templates/_atoms/textarea/_script';
import accordion from '../../../templates/_organisms/accordion/_script';
import gridOverlay from '../../../templates/_molecules/gridOverlay/_script';
import vueComp from '../../../templates/_molecules/vueComp/_script';
import cards from '../../../templates/_organisms/cards/_script';
import appHeader from '../../../templates/_organisms/appHeader/script';


// Modules Array
const modulesArray = [
  appHeader,
  codeSnippet,
  demoForm,
  imageSlider,
  imageGallery,
  input,
  textarea,
  accordion,
  gridOverlay,
  vueComp,
  cards,
];

const modules = {
  // Modules
  Modules() {
    if (modulesArray.length > 0) {
      modulesArray.forEach((module) => {
        module.init();
      });
    }
  },

  init() {
    this.Modules();
  },
};

export default modules;
